import React from "react";
import styled from "@emotion/styled";

import { DefaultLayout } from "../components/layout/defaultLayout";

const NotFoundPage = () => {
  return (
    <DefaultLayout pageTitle="ホーム" headerTitle="HOME">
      <SWrapper>
        <h1>
          404
          <br />
          NOT FOUND
        </h1>
      </SWrapper>
    </DefaultLayout>
  );
};

export default NotFoundPage;

const SWrapper = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
